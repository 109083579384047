/*eslint-disable */
import React, { useState } from "react";
import _ from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  createStyles,
  fade,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import { Tooltip, Button as CoreButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GetApp from "@material-ui/icons/GetApp";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CSVReader, { IFileInfo } from "react-csv-reader";
import { useNavigate } from "react-router-dom";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";
import { HeaderGroup, Row } from "react-table";
import MoreActions from "./MoreActions";
import { ITableActions } from "apps/things/app/item/context/ItemProvider";
import { replaceAll } from "utils";
import Popover from '@material-ui/core/Popover';
import GridItem from "@components/Grid/GridItem";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from "@components/CustomButtons/Button";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { CSVLink } from "react-csv";
import GridHalfWrapper from "@components/Grid/GridHalfWrapper";
import OVSForm from "@components/Form/OVSForm";
export type Data = object[];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontSize: 16,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    addButton: {
      marginLeft: 20,
      color: whiteColor,
    },
    link: { color: "white" },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface IProps {
  children: React.ReactNode;
  tableTitle?: string;
  bulkDelete?: () => void;
  checkedIds?: string[];
  addItemPageLink?: string;
  addItemBtnText?: string;
  data: any;
  headerGroups: HeaderGroup;
  uploadCsv?: (data: any[], fileInfo: IFileInfo) => any;
  handleFilterWithDates?: (toDate: Date, fromDate: Date) => any;
  handleResetFilterWithDates?: (columnName?: string) => any;
  handleCreateSimulatorItem?: () => any;
  handleProcessDownloadItems?: () => any;
  selectedFlatRows: Row[];
  tableActions?: ITableActions[];
  extendedToolbar?: () => React.ReactNode;
  exportData?: any;
  downloadLoading?: any;
  isDistributorStaff?: boolean;
  searchClientFleets?: (search:string) =>  any;
  distributorFleets?: any;
}

const TableToolBar: React.FC<IProps> = ({
  addItemPageLink,
  addItemBtnText = "Add",
  data,
  headerGroups,
  selectedFlatRows,
  exportData,
  downloadLoading,
  uploadCsv = (data, info) => { },
  handleFilterWithDates = (toDate, fromDate) => { },
  handleResetFilterWithDates = (columnName) => { },
  handleCreateSimulatorItem = () => { },
  handleProcessDownloadItems = () => { },
  extendedToolbar,
  tableActions,
  isDistributorStaff,
  searchClientFleets,
  distributorFleets,
  ...rest
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const [csvData, setCsvData] = useState<Data>([] as Data);
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
  };

  const getHeader = (item: string) => {
    if (item === "Date Created") {
      return "node.createdAt";
    }
    if (item === "Last Updated") {
      return "node.updatedAt";
    }
    return item;
  };
  React.useEffect(() => {
    if (selectedFlatRows.length < 1) return;
    const headers: { header: string; id: string }[] = [];
    headerGroups.headers.map((item) => {
      if (item.Header && item.Header !== "Actions")
        return headers.push({
          header: item.Header as string,
          id: item.id as string,
        });
    });

    const downloadData = [];

    for (let index = 0; index < selectedFlatRows.length; index++) {
      let rowData: any = {};
      for (var i = 1; i < headers.length; i++) {
        rowData[headers[i].header] = _.get(
          selectedFlatRows[index].values,
          getHeader(headers[i].id)
        );
      }

      for (const key of Object.keys(rowData)) {
        let value = rowData[key];
        if (
          typeof value !== "number" ||
          typeof value !== "object" ||
          value !== null ||
          value !== undefined
        ) {
          rowData[key] = replaceAll(value?.toString() || "", ",", "_");
        }
      }

      downloadData.push(rowData);
    }
    setCsvData(downloadData);
  }, [selectedFlatRows]);

  const filteredActions = isDistributorStaff
    ? tableActions?.filter((it) => it.title === "Assign Items to Agent")
    : tableActions?.filter((it) => it.title !== "Assign Items to Agent");
  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          className="csv-container"
          style={{ paddingLeft: 0, paddingRight: 0, color: "#757575" }}
        >
          <div style={{}}>
            <div style={{ display: `${!isDistributorStaff ? "block" : "none"} ` }}>
              <Tooltip id="tooltip-top" title={addItemBtnText} placement="top">
                <CoreButton
                  onClick={() => {
                    addItemPageLink && history(addItemPageLink)
                    handleCreateSimulatorItem()
                  }}
                >
                  <AddIcon />
                  &nbsp; Add
                </CoreButton>
              </Tooltip>

              <Tooltip id="Import" title="Import" placement="top">
                <label
                  htmlFor="csv-file-"
                  className="py-2 px-3 MuiButtonBase-root MuiIconButton-root border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out"
                >
                  <CloudUploadIcon color="inherit" />
                </label>
              </Tooltip>

              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <>
                    <Tooltip id="Import" title="Download Items" placement="top">
                      <CoreButton {...bindTrigger(popupState)}>
                        <GetApp style={{ color: "#757575" }} />
                      </CoreButton>
                    </Tooltip>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ margin: "10px" }}>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleProcessDownloadItems()
                            }}
                          >
                            {downloadLoading ? "Loading..." : "Process Items"}
                          </Button>
                        </div>
                        {exportData && (
                          <div style={{ margin: "10px" }}>
                            <Button
                              color="success"
                              style={{ width: "100%" }}
                            >
                              <CSVLink
                                filename='data.csv'
                                color='inherit'
                                data={exportData}
                                target='_blank'
                                style={{ color: 'white' }}
                              >
                                Export Items
                              </CSVLink>
                            </Button>
                          </div>)}
                      </GridItem>
                    </Popover>
                  </>
                )}
              </PopupState>
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <>
                    <Tooltip id="Import" title="Filter by Date" placement="top">
                      <CoreButton {...bindTrigger(popupState)}>
                        <FilterListIcon style={{ color: "#757575" }} />
                      </CoreButton>
                    </Tooltip>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      style={{ width: "70%" }}
                    >
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ display: "flex" }}>
                          <GridHalfWrapper>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="From Date"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </GridHalfWrapper>
                          <GridHalfWrapper>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="To Date"
                                value={toDate}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </GridHalfWrapper>
                        </div>
                        <div>
                          <OVSForm formFieldsData={distributorFleets} />
                        </div>
                        <div style={{ margin: "10px", display: "flex", justifyContent: "space-between" }}>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleFilterWithDates(fromDate || new Date(), toDate || new Date());
                              popupState.close();
                            }}
                          >
                            Search
                          </Button>
                          <Button
                            color="warning"
                            onClick={() => {
                              handleResetFilterWithDates('reset');
                              popupState.close();
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </GridItem>
                    </Popover>
                  </>
                )}
              </PopupState>
            </div>

            {extendedToolbar && extendedToolbar()}
            {
              (selectedFlatRows.length > 0 ||
                (filteredActions && filteredActions?.filter((it) => it.bulkAction == true)?.length > 0)) && (
                <MoreActions
                  selectedFlatRows={selectedFlatRows}
                  csvData={csvData}
                  tableActions={filteredActions}
                  {...rest}
                />
              )
            }
            {selectedFlatRows.length > 0 && (
              <div>Total {selectedFlatRows.length} Items Selected</div>
            )}
            <CSVReader
              cssClass="csv-reader-input"
              label=""
              onFileLoaded={uploadCsv}
              inputId="csv-file-"
              inputStyle={{ color: "black" }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <div className="csv-container"></div>
    </div>
  );
};

export default TableToolBar;
