/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  usePagination,
  Row,
  Column,
  useExpanded,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
  FilterType,
} from "react-table";
import { useSticky } from "react-table-sticky";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
// @ts-ignore noqa: package deprecated
import { matchSorter } from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomInput from "@components/CustomInput/CustomInput.js";
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Styles } from "./StickyHeaderStyles";
import ToggleColumnList from "./ToggleColumnList";
import Pagination from "./Pagination";
import TableToolBar from "./TableToolBar/TableToolBar";
import NoDataFound from "@components/NoDataFound/NoDataFound";
import { useSelector } from "react-redux";
import { RootState } from "store/epics/userPrefEpics";
import { useLocation } from "react-router";
import { TableSortLabel } from "@material-ui/core";
import TableSearchBox from "./TableToolBar/TableSearchBox";
import TableLoader from "@components/Loaders/TableLoader";
import { IFileInfo } from "react-csv-reader";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { ITableActions } from "apps/things/app/item/context/ItemProvider";
import { getPaginationPage, parsePaginationOffset } from "utils";
import { numberWithCommas } from "utils/index";
import { IAdvancedQuery } from "./TableToolBar/AdvancedSearch/types";
import ContentLoader from "react-content-loader";
import { PAGE_LIST_LIMIT } from "@constants";
import { funNumberAgr } from "@types";
import useNotifier from 'hooks/useNotifier';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import moment from "moment";
import FilterChips from "./FilterChips";
// const PAGE_LIST_LIMIT = 100
const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

// @ts-ignore noqa: styles are in .js
const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: {
  column: { filterValue: string; preFilteredRows: Row[]; setFilter: Function };
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e: React.FormEvent<HTMLInputElement>) => {
          setFilter(e.currentTarget.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`,
      }}
    />
  );
}

function fuzzyTextFilterFn(rows: any, id: number, filterValue: string) {
  return matchSorter(rows, filterValue, {
    keys: [(row: Row) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val;

interface IOvesTableProps {
  columns: Column[];
  data: any;
  bulkDelete?: () => void;
  checkedIds?: string[];
  tableTitle?: string;
  isLoading?: boolean;
  renderRowSubComponent?: ({ row }: { row?: Row }) => React.ReactNode;
  addItemPageLink?: string;
  addItemBtnText?: string;
  uploadCsv?: (data: any[], fileInfo: IFileInfo) => any;
  handleFilterWithDates?: (toDate: Date, fromDate: Date) => any;
  handleResetFilterWithDates?: (columnName?: string) => any;
  handleCreateSimulatorItem?: () => any;
  handleProcessDownloadItems?: () => any;
  searchClientFleets?: (search:string) => any;
  distributorFleets?:any
  hideRightToolbar?: boolean;
  tableActions?: ITableActions[];
  isDistributorStaff?: boolean;
  setSearchQuery?: (searchQuery: string) => void;
  goTo?: (nextPrev: boolean) => void;
  paging?: any;
  advancedSearch?: (queries: IAdvancedQuery[]) => void;
  queries?: IAdvancedQuery[];
  setQueries?: (queries: IAdvancedQuery[]) => void;
  refetchData?: () => void;
  hiddenColumns?: string[];
  setOffset?: (offset: number | string) => void;
  extendedToolbar?: () => React.ReactNode;
  setPageLimit?: funNumberAgr;
  searchQuery?: string;
  totalAmount?: number;
  totalCreditAmount?: number;
  filterOptions?: any;
  exportData?: any;
  downloadLoading?: boolean;


}
// Our table component
const OvesTable: React.FC<IOvesTableProps> = ({
  columns,
  data = [],
  isLoading,
  renderRowSubComponent,
  hideRightToolbar,
  setSearchQuery,
  goTo,
  paging,
  advancedSearch,
  queries,
  setQueries,
  refetchData,
  hiddenColumns: _hiddenColumns,
  setOffset,
  setPageLimit,
  searchQuery,
  totalAmount,
  totalCreditAmount,
  filterOptions,
  isDistributorStaff,
  ...rest
}) => {
  const [numberOfRows, setNumberOfRows] = React.useState(PAGE_LIST_LIMIT);
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: Row[], id: number, filterValue: string) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const hiddenColumns: string[] = _hiddenColumns || ([] as string[]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, expanded },
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    toggleGroupBy,
    setGlobalFilter,
    canNextPage,
    setPageSize,
    gotoPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: data || [],
      defaultColumn: defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes: filterTypes as  FilterType<{}>,
      initialState: { pageSize: numberOfRows, pageIndex: 0, hiddenColumns },
    },

    useGlobalFilter,
    useSortBy,
    // useFilters, // useFilters!
    useExpanded,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  const userColumns = useSelector((store: RootState) => store.userPref.columns);
  const rowsPerPage = useSelector(
    (store: RootState) => store.userPref.rowsPerPage || PAGE_LIST_LIMIT
  );
  const location = useLocation();
  const [initialHeaderGroups] = useState(headerGroups[0]);
  const [headers, setHeaders] = useState(headerGroups[0]);
  const [filteredOutHeaders, setFilteredOutHeaders] = useState<any[]>([])
  const [removedHeaders, setRemovedHeaders] = useState<string[]>([]);
  const handleUpdateHeader = (remodedHeaders: string[]) => {
    const temp = initialHeaderGroups.headers.filter(
      (item) => !remodedHeaders.includes(item.Header as string)
    );
    setFilteredOutHeaders(remodedHeaders)
    setHeaders({
      ...headers,
      headers: temp,
    });
  };
  useEffect(() => {
    setPageSize(rowsPerPage);
    if (rowsPerPage && rowsPerPage !== numberOfRows) {
      setNumberOfRows(rowsPerPage);
    }
  }, [rowsPerPage, numberOfRows]);

  useEffect(() => {
    if (Object.keys(userColumns).includes(location.pathname)) {
      setRemovedHeaders(userColumns[location.pathname]);
      handleUpdateHeader(userColumns[location.pathname]);
    }
  }, [userColumns]);
  // Specify the width threshold for when you want to execute the code
  const thresholdWidth = 600;
  // Check if the current viewport width is greater than or equal to the threshold
  if (window.innerWidth <= thresholdWidth) {
    for (let i = 0; i < headers.headers.length; i++) {
      if (i > 3) {
        headers.headers[i]['isVisible'] = false;
      }
    }
  }
  const notify = useNotifier()
  let filteredFilters: any = []
  let dateFilter: any = {}


  console.log(filterOptions, "Filter Options-------303----")
  if (filterOptions) {
    // Filter out the date filter if both fromDate and toDate are null
    filteredFilters = filterOptions.filter((filter: any) => {
      if (filter.field === 'date') {
        return filter?.value?.from !== null || filter?.value?.to !== null;
      }
      if (filter.type === 'status') {
        return filter.status !== null;
      }
      return true; // For other filter types
    });

    console.log(filteredFilters, "Filtered Filters ------312-----")

    dateFilter = filterOptions.find((filter: any) => filter.type === 'date');
  }

  const [shadow, setShadow] = useState(false);
  const handleScroll = (e:any) => {
    if (e.target.scrollLeft > 0) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };
  return (
    <>
      <div className="ReactTable -striped -highlight"  style={{ maxHeight: "85vh" }}>
        <div className="pagination-top">
          <div className="-pagination">
            <div style={{ width: "100%" }} className="row-center">
              <GridContainer className={classes.gridContainer}>
                {(!hideRightToolbar || isDistributorStaff) && (
                  <GridItem xs={12} sm={4} md={4}>
                    <TableToolBar
                      data={data}
                      selectedFlatRows={selectedFlatRows}
                      headerGroups={headers}
                      isDistributorStaff={isDistributorStaff}
                      {...rest}
                    >
                      <></>
                    </TableToolBar>
                  </GridItem>
                )}
                <GridItem xs={12} sm={8} md={(!hideRightToolbar || isDistributorStaff) ? 8 : 12}>
                  <GridContainer>
                    <GridItem className={"flex"} md={6}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <TableSearchBox
                          setGlobalFilter={setGlobalFilter}
                          setSearchQuery={setSearchQuery}
                          headers={headers}
                          advancedSearch={advancedSearch}
                          queries={queries}
                          setQueries={setQueries}
                          searchQuery={searchQuery}
                          refetchData={refetchData}
                        />
                        <ToggleColumnList
                          handleUpdateHeader={handleUpdateHeader}
                          headerGroups={headers}
                          setRemovedHeaders={setRemovedHeaders}
                          removedHeaders={removedHeaders}
                        />
                      </div>
                    </GridItem>
                    <GridItem md={6}>
                      <div
                        className={classes.paginationMargin}
                        style={{ float: "right", marginTop: 20 }}
                      >
                        {!isLoading ? (
                          <span>
                            {" "}
                            {paging ? (
                              <>
                                {paging?.start ||
                                  parsePaginationOffset(paging?.offset, 1)}{" "}
                                -{" "}
                                {paging?.end ||
                                  parsePaginationOffset(
                                    paging?.offset,
                                    numberOfRows
                                  )}{" "}
                                of{" "}
                                {numberWithCommas(
                                  paging?.total || paging?.count || 0
                                )}{" "}
                                rows
                              </>
                            ) : (
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            )}
                          </span>
                        ) : (
                          <ContentLoader
                            height={20}
                            speed={1}
                            viewBox="0 0 250 40"
                          >
                            {/* Only SVG shapes */}
                            {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" /> */}
                            <rect
                              x="0"
                              y="0"
                              rx="4"
                              ry="4"
                              width="200"
                              height="43"
                            />
                            {/* <rect x="80" y="40" rx="3" ry="3" width="250" height="10" /> */}
                          </ContentLoader>
                        )}
                      </div>

                      <Pagination
                        visiblePages={getPaginationPage(
                          pageIndex + 1,
                          pageCount
                        )}
                        onClick={gotoPage}
                        pageIndex={pageIndex + 1}
                        canPreviousPage={
                          paging
                            ? paging?.prev || paging?.hasPreviousPage
                            : canPreviousPage
                        }
                        previousPage={goTo ? () => goTo(false) : previousPage}
                        nextPage={(goTo && !canNextPage) ? () => goTo(true) : nextPage}
                        canNextPage={
                          canNextPage ? canNextPage : paging?.next || paging?.hasNextPage
                        }
                        pageCount={pageCount}
                        loading={isLoading}
                        setOffset={setOffset}
                        setPageLimit={setPageLimit}
                        setNumberOfRows={setNumberOfRows}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {filteredFilters.length > 0 ?
                <GridContainer>
                  <FilterChips initialFilters={filteredFilters} {...rest} />
                </GridContainer> : null}
            </div>
          </div>
        </div>
        <Styles onScroll={handleScroll}>
          <table
            {...getTableProps()}
            className="rttable table sticky"
            style={{ width: "100%" }}
          >
            <thead className='rtthead -header header'>
              {!isLoading && headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='rttr tr'>
                  {headerGroup.headers
                    .filter((column) => !filteredOutHeaders.includes(column.Header as string))
                    .map((column, key) => (
                      column.isVisible !== false &&
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={classnames('rtth rtresizable-header th', {
                          '-cursor-pointer': headerGroup.headers.length - 1 !== key,
                          '-sort-asc': column.isSorted && !column.isSortedDesc,
                          '-sort-desc': column.isSorted && column.isSortedDesc,
                          'sticky-column': key < 2 && key > -1 && window.innerWidth >= thresholdWidth,
                          'shadow-right': shadow && key < 2 && key > -1 && window.innerWidth >= thresholdWidth,
                        })}
                      >
                        <div className='rtresizable-header-content'>
                          {column.render('Header')}

                          {column.id !== "selection" ? (
                            <span>
                              {!column.isSorted ? (<UnfoldMore style={{ fontSize: 14, marginBottom: "-3px" }} />) : null}
                            </span>
                          ) : null}

                          {column.id !== "selection" ? (
                            <TableSortLabel
                              active={column.isSorted}
                              // react-table has a unsorted state which is not treated here
                              direction={column.isSortedDesc ? "desc" : "asc"}
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rttbody body">
              {isLoading && (
                <tr>
                  <TableLoader colSpan={headerGroups[0].headers.length} />
                </tr>
              )}

              {!isLoading && page.length === 0 && (
                <tr>
                  <td
                    style={{ textAlign: "center" }}
                    colSpan={headerGroups[0].headers.length}
                  >
                    <NoDataFound />
                  </td>
                </tr>
              )}

              {!isLoading &&
                page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <React.Fragment key={`${row.id}-${i}`}>
                      <tr
                        {...row.getRowProps()}
                        className={classnames(
                          "rttr tr",
                          { " -odd": i % 2 === 0 },
                          { " -even": i % 2 === 1 }
                        )}
                        {...row.getToggleRowExpandedProps()}
                      >
                        {row.cells.map((cell, key) => {
                          if (
                            removedHeaders.includes(
                              cell.column.Header as string
                            )
                          )
                            return null;

                          const cellContent = key >= 2
                            ? (cell.value !== undefined && cell.value !== null && cell.value !== "" ? cell.render('Cell') : '---')
                            : cell.render('Cell');

                          if (key < 4 && window.innerWidth <= thresholdWidth) {
                            return (
                              <td {...cell.getCellProps()} className='rttd td'>
                                {cellContent}
                              </td>
                            );
                          } else if (window.innerWidth >= thresholdWidth) {
                            return (
                              <td {...cell.getCellProps()} className={classnames('rttd td', {
                                'sticky-column': key < 2 && key > -1,
                                'shadow-right': shadow && key < 2 && key > -1,
                              })} style={key === 2 ? { fontWeight: '500' } : {}}>
                                {cellContent}
                              </td>
                            );
                          }
                        })}
                      </tr>
                      {row.isExpanded && window.innerWidth <= thresholdWidth ? (
                        <tr className='tr expanded'>
                          <td colSpan={visibleColumns.length}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            {row.cells
                              .filter((cell, key) => key > 1)
                              .map((cell, key) => (
                                <>
                                  <p className='expanded-row' onClick={() => { navigator.clipboard.writeText(cell.value); notify({ text: 'Text Copied.', status: 'success' }) }} style={{ cursor: "pointer" }} key={key + 2}><strong>{headers.headers[key + 2].render('Header')}</strong><strong className='row-colon' >:</strong> {cell.render("Cell")}</p>
                                </>
                              ))}
                            {renderRowSubComponent &&
                              renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
            {totalAmount ?
              <tfoot>
                <tr>
                  <td colSpan={columns.length + 1} className="sticky-footer">
                    <span>Sub Total Amount: <b>{totalAmount} MZN </b> </span>
                    <br></br>
                    <hr style={{ margin: '5px' }}></hr>
                    <span>
                      {dateFilter?.value?.from ? (
                        <>
                          Total Payment From <b>{moment(dateFilter?.value?.from).format('MMMM Do YYYY')} </b>
                          to <b>{moment(dateFilter?.value?.to).format('MMMM Do YYYY')} </b>is: <b>{totalCreditAmount} MZN</b>
                        </>
                      ) : (
                        <>
                          Total Payment in history is: <b>{totalCreditAmount} MZN</b>
                        </>
                      )}
                    </span>
                  </td>
                </tr>
              </tfoot> : null
            }

          </table>
        </Styles>
      </div>
    </>
  );
};

// Define a custom filter filter function!
function filterGreaterThan(rows: Row[], id: number, filterValue: string) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: number) => typeof val !== "number";

export default OvesTable;
